import { useEffect } from 'react'
import { useInView } from "react-intersection-observer"
import { useAnimation } from "framer-motion"
import { Theme } from 'theme'

import {
  ModalContainer
} from './styles'

interface Props {

  size: "normal";
  children: React.ReactElement;

}

const Container = ({size, children}: Props) => {

  const control = useAnimation()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px'
  })

  useEffect(() => {
    if (inView) {
      control.start("end");
    }
  }, [control, inView]);

  const infoAnimation = { ...Theme.motion.default, delay: 0 }

  return (
    <ModalContainer
      size={size}
      ref={ref}
      variants={Theme.motion.jumpBottom}
      initial="start"
      animate={control}
      transition={infoAnimation}
    >
      
      {children}

    </ModalContainer>
  );
}

export default Container