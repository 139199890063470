import {
  Wrapper,
  FullLogo,
  Block,
  Links,
  CopyRight
} from './styles'

// icons
import FullLogoSRC from 'assets/Logo/full.svg'

// components
import Socials from 'components/Socials'

export default function Footer() {
  return (
    <Wrapper>

      <Block>
        <FullLogo src={FullLogoSRC} loading="lazy" style={{width: '11.4rem'}} />
        <CopyRight>&copy; Copyright {new Date().getFullYear()} {window.location.host}</CopyRight>
      </Block>
      <Links>
        <a href='#'>Privacy</a>
        <a href='#'>Terms of use</a>
      </Links>
      <Block>
        <Socials />
      </Block>

    </Wrapper>
  );
}