import styled from 'styled-components';
import { Button } from 'components/Buttons/styles'

export const Wrapper = styled.div`
  
  padding: 1.6rem;
  background-color: ${(props) => props.theme.colors.darkBlue};
  text-align: center;
  border-radius: inherit;

  ${Button} {
    width: 100%;
    margin: 1.6rem 0;
    padding-bottom: 0;
  }

`;

export const Title = styled.h1`

  ${(props) => props.theme.fonts.interBold28_32};
  color: ${(props) => props.theme.colors.white100};

`;

export const Description = styled.h3`

  ${(props) => props.theme.fonts.interSemibold13Auto};
  color: ${(props) => props.theme.colors.secondary};
  margin-top: .4rem;
  letter-spacing: 0.002em;
  font-size: 1.3rem;

`;

export const Footer = styled.h6`

  ${(props) => props.theme.fonts.interSemibold13Auto};
  color: ${(props) => props.theme.colors.white100};
  letter-spacing: 0.002em;
  font-size: 1.3rem;

`;

export const Banner = styled.div`
  position: relative;
  margin-bottom: 3.2rem;
  pointer-events: none;
  height: 26.5rem;
  background: ${(props) => props.theme.colors.pink100};
  border-radius: .8rem;
  overflow: hidden;
`;

export const BannerImg = styled.img`
  width: 100%;
  user-select: none;
`;

export const BannerText = styled.span`
  position: absolute;
  bottom: 2.4rem;
  left: 50%;
  transform: translateX(-50%);

  font-weight: 800;
  font-size: 5.6rem;
  line-height: 6.8rem;
  letter-spacing: -0.045em;
  text-transform: uppercase;
  width: 100%;
  color: ${(props) => props.theme.colors.white100};

`;