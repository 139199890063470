import {useEffect} from 'react'
import Lottie, {useLottie} from "lottie-react"
import {IconsName} from 'interfaces/IIcons'

// assets
import CheckIcon from './assets/Check.json'
import ClockIcon from './assets/Clock.json'
import DownArrowIcon from './assets/DownArrow.json'
import UpArrowIcon from './assets/UpArrow.json'
import HeartIcon from './assets/Heart.json'
import MainIcon from './assets/Main.json'
import ProfileIcon from './assets/Profile.json'
import SearchIcon from './assets/Search.json'
import SendIcon from './assets/Send.json'
import XIcon from './assets/X.json'

interface Props {
  name: IconsName;
  loop?: boolean;
  autoplay?: boolean;
  style?: object;
  count?: number;
  speed?: number;
  playAnimation?: boolean;
}


export default function IconManager({ name, loop, playAnimation, ...props }: Props) {
  

  const getAnimation = () => {
    switch (name) {
      case "check": return CheckIcon
      case "clock": return ClockIcon
      case "downArrow": return DownArrowIcon
      case "upArrow": return UpArrowIcon
      case "heart": return HeartIcon
      case "main": return MainIcon
      case "profile": return ProfileIcon
      case "search": return SearchIcon
      case "send": return SendIcon
      case "x": return XIcon
      default: return CheckIcon
    }
  }

  const { View, animationItem } = useLottie({
    animationData: getAnimation(),
    ...props
  });

  useEffect(() => {
    if (props.autoplay) animationItem?.play()
    else animationItem?.stop()
  }, [playAnimation])

  return View;
}