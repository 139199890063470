import { useEffect, useRef } from 'react'
import { Theme } from 'theme'
import { useAnimation } from "framer-motion"

import {
  Wrapper,
  FullLogo
} from './styles'

// icons
import FullLogoSRC from 'assets/Logo/full.svg'

// components
import Socials from 'components/Socials'

export default function Header() {

  const control = useAnimation()

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 300) {
      control.start('end')
    } else {
      control.start('start')
    }
  }

  const titleAnimation = { ...Theme.motion.default, delay: 0 }

  return (
    <Wrapper
      variants={Theme.motion.header}
      initial="start"
      animate={control}
      transition={titleAnimation}
    >
      <FullLogo src={FullLogoSRC} loading="lazy" style={{ width: '11.4rem' }} />
      <Socials />
    </Wrapper>
  );
}