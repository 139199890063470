import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 10px;
    ${(props) => props.theme.mediaQueries.mobile} {
     font-size: 9px;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
      font-size: .69444444vw;
    }

    font-family: 'Inter', sans-serif;
    background: ${(props) => props.theme.colors.background};
    overflow-x: hidden;
    ${(props) => props.theme.mixins.hideScroll};
  }
  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  input {
    background: transparent;
    border: none;
    outline: none;
  }
  a {
    text-decoration: none;
  }
  .hiddenAnimation {
    opacity: 0;
  }
`;