// components
import Header from 'components/Header'
import Footer from 'components/Footer'
import WelcomeScreen from 'components/Landing/WelcomeScreen'
import CarouselScreen from 'components/Landing/CarouselScreen'
import FeatureScreen from 'components/Landing/FeatureScreen'
import RegisterScreen from 'components/Landing/RegisterScreen'
import Container from 'components/Landing/Container'

export default function Landing() {
  return (
    <main>
      <Header />
      <WelcomeScreen />
      <Container>
        <CarouselScreen />
        <FeatureScreen />
        <RegisterScreen />
      </Container>
      <Footer />
    </main>
  );
}