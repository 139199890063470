export default function TelegramIcon({ color = '#79778E' }) {

  return (


    <svg width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 4.5668L17.1528 19.3339C17.1528 19.3339 16.7544 20.3578 15.66 19.8667L9.09065 14.6848L9.06019 14.6695C9.94756 13.8498 16.8286 7.48486 17.1293 7.19635C17.5949 6.74952 17.3059 6.48351 16.7653 6.82104L6.60117 13.4616L2.67987 12.1042C2.67987 12.1042 2.06277 11.8784 2.0034 11.3874C1.94326 10.8956 2.70018 10.6295 2.70018 10.6295L18.6861 4.17783C18.6861 4.17783 20 3.58393 20 4.5668Z" fill={color} />
    </svg>


  )

}