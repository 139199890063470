import { toJS } from 'mobx'
import { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import ReactCanvasConfetti from "react-canvas-confetti";

// state
import ConfettiState from './ConfettiState'


const ConfettiSystem = () => {

  const refAnimationInstance = useRef<any>(null);
  const [intervalId, setIntervalId] = useState<any>();
  const getInstance = useCallback((instance: any) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      ConfettiState.setup(0.5,0.5)
      refAnimationInstance.current(toJS(ConfettiState.settings));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(nextTickAnimation);
    }
  }, [intervalId, nextTickAnimation]);


  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {

    if (ConfettiState.show) {
      ConfettiState.draw();
      startAnimation()
    }

  }, [ConfettiState.show])


  return <ReactCanvasConfetti refConfetti={getInstance} style={{
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1000
  }} />

}

export default observer(ConfettiSystem)