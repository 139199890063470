import { useState } from 'react'
import Icon from '../Icon'
import Button from '../Buttons'

import {
  Wrapper,
  InputBlock,
  LeftBlock,
  RightBlock
} from './styles'

interface Props {
  value?: string;
  onChange?: () => void;
  leftItem?: React.ReactElement;
  rightItem?: React.ReactElement;
  height?: string;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  hsaRightButton?: boolean;
  placeholder?: string;
}

export default function Input({ value, onChange, height, leftItem, rightItem, hasLeftIcon, hasRightIcon, hsaRightButton, ...props }: Props) {

  const [hover, setHover] = useState(false)

  let classes = []
  if (height) classes.push(height)
  if (hasLeftIcon) classes.push(hasLeftIcon)
  if (hasRightIcon) classes.push(hasRightIcon)
  if (hsaRightButton) classes.push(hsaRightButton)

  return (
    <Wrapper className={classes.join(' ')}>
      {leftItem}
      {/* <LeftBlock><Icon autoplay={hover} playAnimation={hover} name="check" style={{width: '2.4rem'}} /></LeftBlock> */}
      <InputBlock
        value={value}
        onChange={onChange}
        onFocus={(e: any) => {setHover(true)}}
        onBlur={(e: any) => {setHover(false)}}
        {...props}
      />
      {rightItem}
      {/* <RightBlock><Button type="solid" height="small" >Search</Button></RightBlock> */}
    </Wrapper>
  );
}