import styled from 'styled-components';
import { motion } from "framer-motion";

export const Section = styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  user-select: none;

  padding-bottom: 8rem;


  .btnAnimation {
    margin-top: 1.5rem;
    button {
      padding: 1rem 3.95rem;
      border-radius: .8rem;
    }
    &:hover {
      button {
        transform: scale(1.05);
      }
    }


    ${(props) => props.theme.mediaQueries.mobile} {
      margin-top: 2.4rem;
      button {
        padding: 1.3rem 7.65rem;
      }

    }
  }

  ${(props) => props.theme.mediaQueries.mobile} {


    height: 100%;
    min-height: unset!important;
    padding-top: 4rem;
    padding-bottom: 12.9rem;

  }
`;

export const Title = styled(motion.h1)`

  color: ${(props) => props.theme.colors.white100};
  text-align: center;
  max-width: 102.9rem;

  ${(props) => props.theme.fonts.interSemibold60};
  ${(props) => props.theme.mediaQueries.mobile} {
    ${(props) => props.theme.fonts.interSemibold32_44};
    margin-top: 9.2rem;
    padding: 0 2.7rem;
  };

`;

export const Subtitle = styled(motion.h6)`

  margin-top: 3.2rem;
  ${(props) => props.theme.fonts.interSemibold16};
  color: ${(props) => props.theme.colors.white100};
  display: flex;
  align-items: center;
  gap: .8rem;
  width: 100%;
  display: flex;
  justify-content: center;

  ${(props) => props.theme.mediaQueries.mobile} {
    margin-top: 2.7rem;
    font-size: 1.8rem;
  }

  img {
    height: 2.6rem;
    pointer-events: none;
  }

`;

export const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -2;
  opacity: .07;
  -webkit-mask-image: -webkit-linear-gradient(90deg, rgba(20, 20, 26, 0) 10%, #14141A 100%);
`;