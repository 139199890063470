import styled from 'styled-components';
import { motion } from "framer-motion"


export const Section = styled.div`
  text-align: center;
  overflow: hidden;
`;

export const Title = styled(motion.h1)`

  color: ${(props) => props.theme.colors.white100};
  ${(props) => props.theme.fonts.interSemibold32};

  ${(props) => props.theme.mediaQueries.mobile} {
    ${(props) => props.theme.fonts.interSemibold24};
  };

`;

export const Subtitle = styled(motion.h3)`

  margin-top: .4rem;
  ${(props) => props.theme.fonts.interMedium13};
  color: ${(props) => props.theme.colors.secondary};

  ${(props) => props.theme.mediaQueries.mobile} {
    margin-top: 1.3rem;
    font-size: 1.6rem;
  }

`;

export const Fade = styled.div`

  position: absolute;
  top: 0;
  width: 28.8rem;
  height: 100%;
  background: linear-gradient(270deg, #14141A 0%, rgba(20, 20, 26, 0) 100%);
  z-index: 12;

  ${(props) => props.theme.mediaQueries.mobile} {
    width: 13.8rem;
  }

`;

export const Cards = styled(motion.img)`

  position: relative;
  display: flex;
  gap: 1.2rem;
  pointer-events: none;
  margin-top: 2.4rem;
  transform: translateX(-50%);
  height: 31.8rem;

  ${(props) => props.theme.mediaQueries.mobile} {
    height: 26rem;
  }

`;

export const Wrapper = styled.div`
  position: relative;

  ${Fade}:nth-child(1) {
    left: -.1rem;
    transform: rotate(180deg);
  }
  ${Fade}:nth-child(2) {
    right: 0;
  }
`;