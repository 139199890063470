import styled from 'styled-components';

export const Button = styled.button`
  
  ${(props) => props.theme.fonts.interSemibold13};
  color: ${(props) => props.theme.colors.white100};
  border-radius: 1.2rem;
  border: .1rem solid transparent;
  transition: .3s;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: .4rem;

  &.solid {
    padding: 0 1.6rem;
    background-color: ${(props) => props.theme.colors.pink100};
    &:hover {
      background-color: ${(props) => props.theme.colors.pink70};
    }
  }
  &.outline {
    padding: 0 1.6rem;
    background-color: transparent;
    border: .1rem solid ${(props) => props.theme.colors.white10};
    &:hover {
      border: .1rem solid transparent;
      background-color: ${(props) => props.theme.colors.darkBlue};
    }
  }
  &.small {}
  &.big {
    padding: .5rem 2.4rem;
  }
  &.only-icon {
    padding: .9rem;
    width: 4rem;
    height: 4rem;
  }

`;