import {
  Wrapper,
  Background,
  Text
} from './styles'

export default function Tip({ children = 'Soon', color = '#E82352' }) {
  return (
    <Wrapper>
      <Background color={color}>
        <Text>{children}</Text>
      </Background>
      <svg width="1.3rem" height="2.4rem" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M3.57074 2.14626C2.88075 0.826927 1.51509 0 0.026222 0H-41V24H8.39404C11.3995 24 13.3314 20.8095 11.9386 18.1463L3.57074 2.14626Z" fill={color} />
        </g>
      </svg>
    </Wrapper>
  );
}