export default function TwitterIcon({ color = '#79778E' }) {

  return (


    <svg width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.6 5.89999C20.9 6.19999 20.1 6.4 19.3 6.5C20.1 6 20.8 5.2 21.1 4.3C20.3 4.8 19.5 5.1 18.5 5.3C17.8 4.5 16.7 4 15.6 4C13.4 4 11.6 5.8 11.6 8C11.6 8.3 11.6 8.59999 11.7 8.89999C8.4 8.69999 5.39999 7.1 3.39999 4.7C3.09999 5.3 2.89999 6 2.89999 6.7C2.89999 8.1 3.6 9.3 4.7 10C4 10 3.39999 9.8 2.89999 9.5C2.89999 11.4 4.29999 13.1 6.09999 13.4C5.79999 13.5 5.4 13.5 5 13.5C4.7 13.5 4.5 13.5 4.2 13.4C4.7 15 6.2 16.2 8 16.2C6.6 17.3 4.9 17.9 3 17.9C2.7 17.9 2.4 17.9 2 17.8C3.8 18.9 5.9 19.6 8.2 19.6C15.6 19.6 19.6 13.5 19.6 8.2V7.7C20.4 7.4 21.1 6.69999 21.6 5.89999Z" fill={color} />
    </svg>


  )

}