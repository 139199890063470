const breakpoints = [
  // mobile
  "560px",
  // tablet
  "768px",
  // computer
  "992px",
  // desktop
  "1200px",
  // widescreen
  "1920px"
];

const animations = {
  bounce: ".3s cubic-bezier(0.5,-0.12, 0.27, 1.55) forwards"
}

const colors = {
  darkBlue: "#1E1E27",
  darkBlueLight: "#262631",
  darkBlue2: "#18181F",
  background: "#14141A",
  pink100: "#E82352",
  pink70: "#A81F41",
  white100: "#ffffff",
  white10: "#2B2B31",
  secondary: "#79778E"
};

const gradients = {

};

const fonts = {
  interSemibold13: `
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 4rem;
  `,
  interSemibold16: `
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: -0.02em;
  `,
  interSemibold13Auto: `
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 0.01em;
    line-height: 2rem;
  `,
  interSemibold10: `
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2rem;
  `,
  interSemibold60: `
    font-style: normal;
    font-weight: 600;
    font-size: 6rem;
    line-height: 7.3rem;
  `,
  interSemibold32: `
    font-style: normal;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 4rem;
  `,
  interSemibold24: `
    font-style: normal;
    font-weight: 600;
    font-size: 2.7rem;
    line-height: 3rem;
  `,
  interSemibold24_140: `
    font-style: normal;
    font-weight: 600;
    font-size: 2.7rem;
    line-height: 3.9rem;
  `,
  interSemibold32_44: `
    font-style: normal;
    font-weight: 600;
    font-size: 3.7rem;
    line-height: 4.9rem;
    margin-top: 9rem;
  `,
  interMedium13: `
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 2rem;
  `,
  interBold28_32: `
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.2rem;
  `
}

const mixins = {

  hideScroll: `-ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }`

}

const motion = {
  default: { type: "spring", stiffness: 400, damping: 30, mass: 1 },
  jumpBottom: {
    start: {
      opacity: 0,
      y: 150
    },
    end: {
      opacity: 1,
      y: 0
    }
  },
  header: {
    start: {
      background: 'linear-gradient(180deg, #14141A 0%, rgba(20, 20, 26, 0) 0%)',
      backdropFilter: 'blur(0rem)',
    },
    end: {
      background: 'linear-gradient(180deg, #14141A 0%, rgba(20, 20, 26, 0) 100%)',
    }
  }
}

const theme = {
  breakpoints,
  mediaQueries: {
    mobile: `@media screen and (max-width: ${breakpoints[0]})`,
    tablet: `@media screen and (min-width: ${breakpoints[1]})`,
    computer: `@media screen and (min-width: ${breakpoints[2]})`,
    desktop: `@media screen and (min-width: ${breakpoints[3]})`,
    widescreen: `@media screen and (min-width: ${breakpoints[4]})`
  },
  colors,
  gradients,
  text: {
    title: {
      color: colors.darkBlue
    }
  },
  animations,
  motion,
  fonts,
  mixins
};


export default theme;
