import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: .8rem;

  a * {
    transition: .3s;
  }
  a:hover {
    svg {
      transform: scale(1.1);
     
      path {
        fill: ${(props) => props.theme.colors.white100};
      }
    }

  }
`;