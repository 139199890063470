import { useEffect } from 'react'
import { observer } from "mobx-react-lite";

import {
  Wrapper,
  Container
} from './styles'

// state
import ModalState from "./ModalState";

const ModalSystem = () => {

  useEffect(() => {
    
    const closeOnEscapeKey = (e: any) => (e.key === "Escape" ? ModalState.close() : null)
		document.body.addEventListener("keydown", closeOnEscapeKey)

    return () => {
			document.body.removeEventListener("keydown", closeOnEscapeKey);
		}

  }, [ModalState.component])

  return (
    <Wrapper>
      
      {ModalState.component &&
        <Container>
          {ModalState.component}
        </Container>
      }

    </Wrapper>
  );
}

export default observer(ModalSystem)