import { makeAutoObservable } from "mobx";

interface SettingsProps {
  startVelocity: number;
  spread: number;
  ticks: number;
  particleCount: number;
  origin: {
    x: number;
    y: number;
  }
}

class ConfettiState {
  
  settings: object = <SettingsProps>{
    startVelocity: 130,
    spread: 360,
    ticks: 60,
    particleCount: 150,
    origin: {
      x: 0.5,
      y: 0.5
    },
    colors: ["#E82352", "#D3214C", "#BE2047", "#A81F41", "#931D3C", "#7E1B36", "#691A30", "#54192B"]
  };

  show: boolean = false;


  constructor() {
    makeAutoObservable(this);
  }

  changeSettings(object: object) {

    this.settings = Object.assign(this.settings, object);

  }

  setup(originXA: number = 0.5, originXB: number = 0.5) {

    this.changeSettings({
      origin: {
        x: originXA,
        y: originXB
      }
    })

  }

  draw() {

    this.show = true;
    this.show = false;

  }

}

export default new ConfettiState();
