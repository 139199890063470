import { useEffect } from 'react'
import { useInView } from "react-intersection-observer"
import { useAnimation } from "framer-motion"
import { Theme } from 'theme'
import { LendingUnits } from 'components/Landing/enums'

import {
  Section,
  Item,
  Info,
  Title,
  Description,
  Image,
  Child
} from './styled'

// images
import FindImg from './assets/find.png'
import CreateImg from './assets/create.png'
import TournamentsImg from './assets/tournaments.png'
import StatisticsImg from './assets/statistics.png'

// components
import Tip from 'components/Tip'
import Buttons from 'components/Buttons'

export default function FeatureScreen() {

  return (

    <Section>

      <FeatureItem
        title="FIND TEAMMATES"
        description={`Play only with the players you choose. Find teammates with the same goals in the game.`}
        image={FindImg}
      >
        <Buttons type="solid" height="big" style={{ marginTop: '3.2rem' }}>Find teammates</Buttons>
      </FeatureItem>
      <FeatureItem
        title="CREATE OUR TEAM"
        description={`Find players on our platform to create your own team with common interests, you can join an existing team or create your own.`}
        image={CreateImg}
        soon={true}
      />
      <FeatureItem
        title="TOURNAMENTS"
        description={`Gather a team to play together in tournaments in different games. Participate in duets, trios and team tournaments with cash prizes.`}
        image={TournamentsImg}
        soon={true}
      />
      <FeatureItem
        title="STATISTICS"
        description={`Get the most complete statistics on the game you have chosen. Analyze this data to improve your level of play.`}
        image={StatisticsImg}
        soon={true}
      />

    </Section>

  )

}

interface IFeatureItem {
  title: string;
  description: string;
  image?: any;
  children?: React.ReactElement;
  soon?: boolean;
}

const FeatureItem = ({ title, soon = false, description, image, children }: IFeatureItem) => {

  const control = useAnimation()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: LendingUnits.animationShow
  })

  useEffect(() => {
    if (inView) {
      control.start("end");
    }
  }, [control, inView]);

  const infoAnimation = { ...Theme.motion.default, delay: 0 }

  return (
    <Item>
      <div>
        <Info
          variants={Theme.motion.jumpBottom} initial="start" animate={control} transition={infoAnimation}
          ref={ref}
        >
          <Title>{title} {soon && <Tip>Soon</Tip>}</Title>
          <Description>{description}</Description>
          <Child>{children}</Child>
        </Info>
      </div>
      <Image src={image} />
    </Item>
  )

}