import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 137.6rem;
  width: 100%;
  margin: 0 auto;

  ${(props) => props.theme.mediaQueries.mobile} {
    max-width: 343px;
  }
`;