import { useEffect } from 'react' 
import { useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer"
import { Theme } from 'theme'
import { LendingUnits } from 'components/Landing/enums'

import {
  Wrapper,
  Title,
  Description,
  Form,
  Background,
  BackgroundItem,
  ItemWrapper,
  Fade
} from './styled'

// images
import BackgroundImg from './assets/background.png'
import BackgroundItemImg from './assets/item.png'

// components
import Input from 'components/Input'
import Buttons from 'components/Buttons'
import WhiteListModal from 'components/ModalSystem/Modals/Whitelist'

import ModalState from 'components/ModalSystem/ModalState'

export default function RegisterScreen() {

  const control = useAnimation()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: LendingUnits.animationShowFooter
  })

  useEffect(() => {
    if (inView) {
      control.start("end");
    }
  }, [control, inView]);

  const infoAnimation = { ...Theme.motion.default, delay: 0.1 }

  return (

    <Wrapper
      variants={Theme.motion.jumpBottom} initial="start" animate={control} transition={infoAnimation}
      ref={ref}
    >
      <Title>PRIZE FOR REGISTRATION</Title>
      <Description>Register now and get a<br />mystic box after our launch!</Description>
      <ItemWrapper>
        <BackgroundItem src={BackgroundItemImg} />
      </ItemWrapper>
      <Fade />
      <Form>
        <Input placeholder="Your email" />
        <Input placeholder="Your nickname" />
        <Buttons onClick={() => {
          ModalState.setComponent(<WhiteListModal />)
        }} type="solid" height="big">Join</Buttons>
      </Form>

      <Background src={BackgroundImg} />
    </Wrapper>

  )

}