import {
  Wrapper
} from './styles'

// components
import DiscordIcon from 'components/StaticIcons/Discord'
import InstagramIcon from 'components/StaticIcons/Instagram'
import LinkedinIcon from 'components/StaticIcons/Linkedin'
import TelegramIcon from 'components/StaticIcons/Telegram'
import TwitterIcon from 'components/StaticIcons/Twitter'

export default function Socials() {
  return (
    <Wrapper>
      <a href="#" target="_blank"> <DiscordIcon /> </a>
      <a href="#" target="_blank"> <InstagramIcon /> </a>
      <a href="#" target="_blank"> <LinkedinIcon /> </a>
      <a href="#" target="_blank"> <TelegramIcon /> </a>
      <a href="#" target="_blank"> <TwitterIcon /> </a>
    </Wrapper>
  );
}