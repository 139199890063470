import styled from 'styled-components';
import { motion } from "framer-motion";

export const Wrapper = styled(motion.header)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3.2rem 3.2rem;
  position: fixed;
  top: 0;
  z-index: 20;

`;

export const FullLogo = styled.img`
`;