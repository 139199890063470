import { useEffect } from 'react'
import {
  Wrapper,

  Title,
  Description,
  Footer,
  Banner,
  BannerImg,
  BannerText
} from './styles'

import ModalContainer from '../../Container'
import Button from 'components/Buttons'

// assets
import BannerUrl from './assets/banner.png'

// state
import ModalState from '../../ModalState'
import ConfettiState from 'components/ConfettiSystem/ConfettiState'

export default function WhitelistModal() {

  useEffect(() => {
    ConfettiState.show = true;
  }, [])

  return (
    <ModalContainer size="normal">

      <Wrapper>

        <Banner>
          <BannerImg src={BannerUrl} />
          <BannerText>Сoming soon</BannerText>
        </Banner>

        <Title>You have been added to the whitelist!</Title>
        <Description>We will email you the link when the site is available.</Description>
        <Button
          type="solid"
          height="big"
          onClick={() => ModalState.close()}
        >
          Close
        </Button>
        <Footer>📦 Get Mystery box after launch</Footer>

      </Wrapper>


    </ModalContainer>
  );
}