export default function DiscordIcon({ color = '#79778E' }) {

  return (


    <svg width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5229 6.64667C18.1468 5.60665 16.4954 5.08667 14.7523 5L14.4771 5.26002C16.0367 5.60665 17.4129 6.29998 18.6973 7.25332C17.1376 6.47332 15.3945 5.95334 13.5596 5.78C13.0092 5.69332 12.5504 5.69332 12 5.69332C11.4496 5.69332 10.9908 5.69332 10.4404 5.78C8.6055 5.95334 6.86237 6.47332 5.30275 7.25332C6.58712 6.29998 7.96331 5.60665 9.52294 5.26002L9.24769 5C7.50456 5.08667 5.85319 5.60665 4.47706 6.64667C2.91743 9.42003 2.09174 12.54 2 15.7466C3.37613 17.1333 5.30275 18 7.32113 18C7.32113 18 7.96331 17.3067 8.422 16.7C7.22937 16.44 6.12844 15.8333 5.3945 14.88C6.03669 15.2266 6.67887 15.5733 7.32113 15.8333C8.14681 16.18 8.9725 16.3533 9.79819 16.5267C10.5321 16.6133 11.2661 16.7 12 16.7C12.7339 16.7 13.4679 16.6133 14.2018 16.5267C15.0275 16.3533 15.8532 16.18 16.6789 15.8333C17.3211 15.5733 17.9633 15.2266 18.6055 14.88C17.8716 15.8333 16.7706 16.44 15.578 16.7C16.0367 17.3067 16.6789 18 16.6789 18C18.6973 18 20.6239 17.1333 22 15.7466C21.9083 12.54 21.0826 9.42003 19.5229 6.64667ZM8.9725 14.1866C8.05506 14.1866 7.22938 13.4066 7.22938 12.4533C7.22938 11.5 8.05506 10.72 8.9725 10.72C9.88994 10.72 10.7156 11.5 10.7156 12.4533C10.7156 13.4066 9.88994 14.1866 8.9725 14.1866ZM15.0275 14.1866C14.1101 14.1866 13.2844 13.4066 13.2844 12.4533C13.2844 11.5 14.1101 10.72 15.0275 10.72C15.9449 10.72 16.7706 11.5 16.7706 12.4533C16.7706 13.4066 15.9449 14.1866 15.0275 14.1866Z" fill={color} />
    </svg>


  )

}