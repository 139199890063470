import styled from 'styled-components';
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  
  

`;

export const Container = styled.div`
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(20, 20, 26, 0.9);
  backdrop-filter: blur(.4rem);
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

`;

export const ModalContainer = styled(motion.div)`

  border-radius: 1.2rem;
  margin: 0 1.6rem;

  ${(props) => props.size === 'normal' ? `
  {
    max-width: 50.8rem;
    width: 100%;
  }
  ` : {}}

`;