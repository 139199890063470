import styled from 'styled-components';
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
  padding: 6rem 4.7rem;
  background-color: ${(props) => props.theme.colors.darkBlue2};
  border-radius: 1.2rem;
  margin-left: 8.8rem;
  margin-right: 8.8rem;

  ${(props) => props.theme.mediaQueries.mobile} {
    padding: 2.4rem;
    margin-left: 0;
    margin-right: 0;
    margin-top: 8rem;
    text-align: center;
  };
`;
export const Title = styled.h5`
  ${(props) => props.theme.fonts.interSemibold13Auto};
  color: ${(props) => props.theme.colors.pink100};
`;
export const Description = styled.p`

  ${(props) => props.theme.fonts.interSemibold32};
  color: ${(props) => props.theme.colors.white100};
  margin-bottom: 3.2rem;
  margin-top: .8rem;

  ${(props) => props.theme.mediaQueries.mobile} {
    ${(props) => props.theme.fonts.interSemibold24_140};
    margin-bottom: 0rem;
  }

`;
export const Form = styled.div`
  display: flex;
  gap: 1.2rem;

  input,
  button {
    height: 7.2rem;
  }
  input {
    width: 24.8rem;
  }
  button {
    padding: 0 2.5rem!important;
  }

  ${(props) => props.theme.mediaQueries.mobile} {
    flex-direction: column;
    margin-top: 5rem;
    input,div {
      width: 100%;
    }
  }
`;
export const ItemWrapper = styled.div`

  ${(props) => props.theme.mediaQueries.mobile} {
    position: relative;
    &:before {
      position: absolute;
      z-index: 2;
      width: calc(100% + 5rem);
      height: 14.9rem;
      background: linear-gradient(180deg, rgba(24, 24, 31, 0) 0%, #18181F 100%);
      /* top: 6rem; */
      bottom: -2.6rem;
      left: -2.5rem;
      content: '';
    }
  }
`;
export const Background = styled.img`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  ${(props) => props.theme.mediaQueries.mobile} {
    width: 100%;
    height: 36rem;
  }
`;
export const BackgroundItem = styled.img`
  pointer-events: none;
  position: absolute;
  bottom: -8rem;
  right: 0;
  height: 100%;

  ${(props) => props.theme.mediaQueries.mobile} {
    position: relative;
    width: 100%;
    bottom: -2rem;
    right: 1.5rem;
  }
`;
export const Fade = styled.div`
  width: 36.9rem;
  height: 36.9rem;
  background-color: ${(props) => props.theme.colors.pink100};
  filter: blur(50rem);
  position: absolute;
  bottom: -14.8rem;
  right: -33.7rem;

  ${(props) => props.theme.mediaQueries.mobile} {
    display: none;
  }
`;