import {
  Wrapper
} from './styled'

export default function Container({children}) {

  return (

    <Wrapper>

      {children}

    </Wrapper>

  )

}