import { useEffect, useRef } from 'react'
import { motion, useAnimation } from "framer-motion"
import { Theme } from 'theme'

import {
  Section,
  Title,
  Subtitle,
  BackgroundVideo
} from './styled'

// components
import Buttons from 'components/Buttons'

// media
import ConfettiIcon from './assets/Confetti.png'
import VideoMp4 from './assets/background.mp4'

export default function WelcomeScreen() {

  const vidRef = useRef();
  useEffect(() => {
    vidRef.current.play();
  }, []);

  const control = useAnimation()

  useEffect(() => {
    control.start("end");
  }, [control]);

  const titleAnimation = { ...Theme.motion.default, delay: 0.3 }
  const btnAnimation = { ...Theme.motion.default, delay: 0.4 }
  const subtitleAnimation = { ...Theme.motion.default, delay: 0.5 }

  return (

    <Section>

        <Title
          variants={Theme.motion.jumpBottom} initial="start" animate={control} transition={titleAnimation}
        >
          A place where you can assemble a team to play together without toxic randoms.
        </Title>

      <motion.div
        className="btnAnimation"
        variants={Theme.motion.jumpBottom}
        initial="start"
        animate={control}
        transition={btnAnimation}
      >
        <a aria-type="anchor"><Buttons type="solid">Join</Buttons></a>
      </motion.div>

      <Subtitle
        variants={Theme.motion.jumpBottom}
        initial="start"
        animate={control}
        transition={subtitleAnimation}
      >
        <img src={ConfettiIcon} alt='Confetti' loading='lazy' />
        Get a free Mystic Box for registration
      </Subtitle>

      <BackgroundVideo
        ref={vidRef}
        autoplay={true}
        loop
        preload
        src={VideoMp4}
        muted
        playsinline
        type="video/mp4"
      ></BackgroundVideo>

    </Section>

  )

}