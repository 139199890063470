import { Theme } from 'theme'
import { useInView } from "react-intersection-observer"
import { useAnimation } from "framer-motion"

import {
  useEffect,
  useRef
} from 'react'

import {
  Section,
  Title,
  Subtitle,
  Cards,
  Card,
  Fade,
  Wrapper
} from './styled'

// assets
import LineImages from './assets/line.png'

export default function CarouselScreen() {

  const cardsScroll = useRef(null)
  const control = useAnimation()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-5%'
  })

  useEffect(() => {

    const onScroll = () => cardsScroll.current.style.left = `${window.pageYOffset * 0.65}px`
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll, { capture: true, passive: true });

  }, [])

  useEffect(() => {
    if (inView) {
      control.start("end");
    }
  }, [control, inView]);

  const titleAnimation = { ...Theme.motion.default, delay: 0 }
  const subtitleAnimation = { ...Theme.motion.default, delay: 0.1 }
  const cardsAnimation = { ...Theme.motion.default, delay: 0.3 }

  return (

    <Section ref={ref}>

      <Title
        variants={Theme.motion.jumpBottom} initial="start" animate={control} transition={titleAnimation}
      >Find teammates in different games</Title>

      <Subtitle
        variants={Theme.motion.jumpBottom} initial="start" animate={control} transition={subtitleAnimation}
      >ThePrime.gg currently has 3 main games in which you can search for teammates to play together.</Subtitle>

      <Wrapper>

        <Fade></Fade>
        <Fade></Fade>
        <Cards
          ref={cardsScroll}
          src={LineImages}
          variants={Theme.motion.jumpBottom}
          initial="start"
          animate={control}
          transition={cardsAnimation}
          style={{translateX: '-50%'}}
        />

      </Wrapper>

    </Section>

  )

}