import {useState} from 'react'
import Icon from '../Icon'
import {IconsName} from 'interfaces/IIcons'

import {
  Button
} from './styles'

interface Props {
  type: "solid" | "outline";
  height: "small" | "big";
  onlyIcon?: boolean;
  children?: React.ReactElement | string;
  leftIcon?: IconsName;
  rightIcon?: IconsName;
  style?: object;
  onClick?: () => void;
}

export default function ButtonUI({ type, height, onlyIcon, children, leftIcon, rightIcon, ...props }: Props) {

  let classes = []
  if (type) classes.push(type)
  if (height) classes.push(height)
  if (onlyIcon) classes.push('only-icon')

  let IconSetting = {width: '2.2rem', height: '2.2rem', display: 'flex'}

  const [hover, setHover] = useState(false)

  return (
    <Button
      className={classes.join(' ')}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      {leftIcon && <Icon autoplay={hover} playAnimation={hover} style={IconSetting} name={leftIcon} />}
      {!onlyIcon && <>{children}</> }
      {rightIcon && <Icon autoplay={hover} playAnimation={hover} style={IconSetting} name={rightIcon} />}
    </Button>
  );
}