export default function LinkedinIcon({ color = '#79778E' }) {

  return (

    <svg width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 13.7444V19.2601H15.7713V14.0807C15.7713 12.8027 15.3004 11.9283 14.157 11.9283C13.2825 11.9283 12.7444 12.5336 12.5426 13.0718C12.4753 13.2735 12.4081 13.5426 12.4081 13.8789V19.2601H9.17937C9.17937 19.2601 9.24664 10.5157 9.17937 9.64126H12.4081V10.9865C12.8117 10.3139 13.6188 9.3722 15.3004 9.3722C17.3856 9.3722 19 10.7848 19 13.7444ZM5.81614 5C4.73991 5 4 5.73991 4 6.68161C4 7.62332 4.67264 8.36323 5.74888 8.36323C6.89238 8.36323 7.56502 7.62332 7.56502 6.68161C7.63229 5.67265 6.95964 5 5.81614 5ZM4.20179 19.2601H7.43049V9.64126H4.20179V19.2601Z" fill={color} />
    </svg>

  )

}