import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Text = styled.span`
  ${(props) => props.theme.fonts.interSemibold13Auto};
  color: ${(props) => props.theme.colors.white100};
`;

export const Background = styled.div`
  background-color: ${(props) => props.color};
  padding: .2rem 0 .2rem .8rem;
  border-radius: .4rem 0 0 .4rem;
`;