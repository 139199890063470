import { memo, Suspense } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { Theme } from "../theme"


import Landing from 'Pages/Landing'

const Provider = ({ children }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />}>
            </Route>
          </Routes>
        </BrowserRouter>
        {children}
      </ThemeProvider>
    </Suspense>
  )
}

export default memo(Provider)