import styled from 'styled-components';
import { Wrapper as Socials } from 'components/Socials/styles'

export const Wrapper = styled.footer`
  width: 100%;
  display: grid;
  grid-template-columns: 30% 1fr 30%;
  align-items: center;
  justify-content: space-between;
  padding: 1.7rem 4rem;
  border-top: .1rem solid ${(props) => props.theme.colors.darkBlue};
  margin-top: 4rem;

  ${(props) => props.theme.mediaQueries.mobile} {
    display: flex;
    flex-wrap: wrap;
    padding: 1.7rem 2.4rem;
  }
`;

export const FullLogo = styled.img`
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.6rem;
  width: 100%;
  &:nth-child(3) {
    div {
      width: 100%;
      justify-content: flex-end;
    }
  }

  ${(props) => props.theme.mediaQueries.mobile} {
    ${Socials} {
      margin-top: 2.4rem;
      justify-content: center!important;
    }
  }

`;
export const Links = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.7rem;
  a {
    ${(props) => props.theme.fonts.interMedium13};
    color: ${(props) => props.theme.colors.secondary};
  }

  ${(props) => props.theme.mediaQueries.mobile} {
    width: 100%;
    margin-top: 1.6rem;
  }
  
`;
export const CopyRight = styled.span`
  ${(props) => props.theme.fonts.interMedium13};
  color: ${(props) => props.theme.colors.secondary};
`;