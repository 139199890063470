import styled from 'styled-components';

export const InputBlock = styled.input`
  border-radius: 1.2rem;
  background: ${(props) => props.theme.colors.darkBlue};
  ${(props) => props.theme.fonts.interSemibold13};
  color: ${(props) => props.theme.colors.secondary};
  outline: .1rem solid transparent;
  transition: .3s;
  width: 100%;
  padding: .4rem 1.6rem;

  &:hover {
    outline: .1rem solid ${(props) => props.theme.colors.white10};
  }
  &:focus {
    outline: .2rem solid ${(props) => props.theme.colors.darkBlue};
    background: transparent;
  }
`;

export const LeftBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 1.2rem;
  transform: translateY(-50%);
`;

export const RightBlock = styled.div`
  position: absolute;
  top: 50%;
  right: 1.2rem;
  transform: translateY(-50%);
`;


export const Wrapper = styled.div`
  position: relative;
  width: fit-content;

  &.big {
    ${InputBlock} {
      padding: .8rem .8rem .8rem 1.2rem;
    }
  }
  &.small {
    ${InputBlock} {
      padding: .4rem 1.2rem;
    }
  }
  &.hasLeftIcon {
    ${LeftBlock} {
      left: 1.2rem;
    }
    ${InputBlock} {
      padding-left: 4.4rem;
    }
  }
  &.hasRightIcon {
    ${RightBlock} {
      right: 1.2rem;
    }
  }
  &.hsaRightButton {
    ${RightBlock} {
      right: .8rem;

      button {
        border-radius: .8rem;
      }
    }
  }
`;