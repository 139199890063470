import { makeAutoObservable } from "mobx";

class ModalSystem {
  component: any = false;


  constructor() {
    makeAutoObservable(this);
  }

  setComponent(component: any) {

    this.component = component;

  }

  close() {

    this.component = false;

  }

}

export default new ModalSystem();
