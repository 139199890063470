import styled from 'styled-components';
import { motion } from "framer-motion";

export const Section = styled.div`
  margin: 18rem 8.8rem 0 8.8rem;

  ${(props) => props.theme.mediaQueries.mobile} {
    margin: 11.9rem 0 0 0;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  margin-bottom: 11.8rem;

  ${(props) => props.theme.mediaQueries.mobile} {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    margin-bottom: 7.2rem;
  }
`;
export const Info = styled(motion.div)`
  
`;
export const Title = styled.h2`
  ${(props) => props.theme.fonts.interSemibold13Auto};
  color: ${(props) => props.theme.colors.pink100};
  display: flex;
  align-items: center;
  gap: .8rem;

  ${(props) => props.theme.mediaQueries.mobile} {
    justify-content: center;
  }
`;
export const Description = styled.p`
  ${(props) => props.theme.fonts.interSemibold32};
  color: ${(props) => props.theme.colors.white100};
  margin-top: .8rem;
  white-space: pre-line;

  ${(props) => props.theme.mediaQueries.mobile} {
    ${(props) => props.theme.fonts.interSemibold24_140};
  }
`;
export const Image = styled.img`
  max-width: 48rem;
  width: 100%;

  ${(props) => props.theme.mediaQueries.mobile} {
    width: 100%;
    height: auto;
    margin-top: 2.4rem;
  }
`;
export const Child = styled.div`
  width: 100%;
  
  ${(props) => props.theme.mediaQueries.mobile} {
    display: flex;
    justify-content: center;
  }
`;